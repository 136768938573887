import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/layout.de"
import Image from "../components/image"
import SEO from "../components/seo"

import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"
import EmotionHeader, { Text, Content } from "../components/common"


import { useTopicImage } from "../components/hooks/useTopicImage"

const IndexPage = props => {
  const topicImage = useTopicImage()
  const backgroundFluidImageStack = [
    topicImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.33), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    pageTitle: "Speisekarte",
    subTitle: "Wir lieben Kuchen",
    sectionTitle: "Wir lieben Kuchen...",
    seoTitle: "Speisekarte: Kuchen, Cafe, Frühstück den ganzen Tag",
  }

  const formConfig = {
    id: "tina-tutorial-index",
    label: "Edit Page",
    fields: [
      {
        name: "pageTitle",
        label: "Page Title",
        component: "text",
      },
      {
        name: "subTitle",
        label: "Subtitle",
        component: "text",
      },
      {
        name: "seoTitle",
        label: "SEO Title",
        component: "text",
      },
    ],
    initialValues: pageData,
    onSubmit: async () => {
      window.alert("Saved!")
    },
  }

  const GalleryImage = styled.div`
    ${props =>
      css`
        background-image: url("${props.imageSrc}");
      `}
    ${tw`h-full w-full bg-cover rounded-sm shadow-md`}
  `

  return (
    <Layout
      location={props.location}
      showHeader={false}
      imageData={backgroundFluidImageStack}
      title={pageData.pageTitle}
    >
      <SEO title={pageData.sectionTitle} />

      <Content>
          <EmotionHeader
            title="Unsere Speisekarte"
            subTitle="wird gerade überarbeitet"
          />
          <Text>
            <div>
              
Vielen Dank, dass du bei uns vorbeischaust! Wir möchten dich darauf hinweisen, dass wir derzeit unsere Speisekarte aktualisieren. <br /><br />
 
            </div>
            <div>
      Bei uns kannst du jedoch weiterhin unser hausgemachtes Kuchen- und Tortenangebot genießen, sowie frisch gebackene Waffeln und Pfannkuchen.<br /><br />
      
      Wenn du herzhafte Speisen bevorzugst, bieten wir dir eine Auswahl an Flammkuchen, La Flute und Waffeln mit Lachs.<br /><br />
      
      Außerdem servieren wir ein köstliches herzhaftes Frühstück. <br /><br />
      

                </div>

<div>
        
              Bei uns kannst du auch zwischen verschiedenen Kaffeesorten und Tees wählen oder dich mit erfrischenden Kaltgetränken abkühlen. 
              <br /><br />
              Wir hoffen, dass du dich bei uns wohlfühlst und dich von unserem Angebot überzeugen lässt.

              </div>


          </Text>
        </Content>
    </Layout>
  )
}

export default IndexPage
