import { useStaticQuery, graphql } from "gatsby"

//Speisekarte-2.jpg
export const useTopicImage = () => {
  const { topicImage } = useStaticQuery(
    graphql`
      query TopicImage {
        topicImage: file(relativePath: { eq: "newset01/DSC03366.png" }) {
          childImageSharp {
            fluid(quality: 40, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return topicImage
}
